//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.



// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
// $font-family-base: 'Inter', sans-serif !default;

// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #215BFF;
$primary-2:     #3D4350;
// $primary-3:     #000;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

a {
    cursor: pointer;
}